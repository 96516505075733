import * as React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { styled, useTheme } from "@mui/material/styles";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import NavbarV2 from './NavbarV2';

import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import useMediaQuery from '@mui/material/useMediaQuery';
import PhoneIcon from '@mui/icons-material/Phone';
import TextField from '@mui/material/TextField';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import image1 from './furkanv1.jpg';
import { Card } from '@mui/material';
import image2 from  './furkanv2.jpg';
import image3 from  './furkanv3.jpg';
import image4 from './furkanv4.jpg';

import Testimonials from './Testimonials';
import LocationHours from './Locationhours';
import Servicesprovided from './Servicesprovided';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Import the stylesheet
import BASE_URL from './ipconfig'; // Import the BASE_URL
import StarScore from './Starscore';
import Awards from './Awards';
import ThinNavbar from './Thinnavbar';
import Hiring from './Hiring';
import Hiringv2 from './Hiringv2';
import Guarantee from './Guarantee';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const HeroBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '400px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2),
  transition: 'background-image 1s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
    padding: theme.spacing(1),
  },
}));

const SectionBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  boxShadow: theme.shadows[3],
  marginBottom: theme.spacing(4),
}));

const urlify = (text) => {
  const urlPattern = /(https?:\/\/[^\s]+)/g;
  return text.split(urlPattern).map((part, index) => {
    if (urlPattern.test(part)) {
      return (
        <Link href={part} target="_blank" rel="noopener noreferrer" key={index}>
          {part}
        </Link>
      );
    }
    return part;
  });
};

export default function Home() {
  const [announcements, setAnnouncements] = useState([]);
  const [events, setEvents] = useState([]);
  const [pictures, setPictures] = useState([]);
  const [videos, setVideos] = useState([]);
  const [dialogues, setDialogues] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState({});
  const [expanded, setExpanded] = useState({});
  const [currentPictureIndex, setCurrentPictureIndex] = useState(0);
  const [imageDimensions, setImageDimensions] = useState({ width: '90vw', height: '90vh' });
  const [openBookDialog, setOpenBookDialog] = useState(false);

  const [bookingDate, setBookingDate] = useState(new Date());
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [picturesToUpload, setPicturesToUpload] = useState([]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const pictureReel = [image1, image2, image3, image4];

  useEffect(() => {
    fetchAnnouncements();
    fetchEvents();
    fetchPictures();
    fetchVideos();
    fetchDialogues();

    const pictureInterval = setInterval(() => {
      setCurrentPictureIndex((prevIndex) => (prevIndex + 1) % pictureReel.length);
    }, 10000);

    return () => clearInterval(pictureInterval);
  }, []);

  const fetchAnnouncements = async () => {
    const response = await axios.get(`${BASE_URL}/announcements`);
    setAnnouncements(response.data);
  };

  const fetchEvents = async () => {
    const response = await axios.get(`${BASE_URL}/events`);
    setEvents(response.data);
  };

  const fetchPictures = async () => {
    const response = await axios.get(`${BASE_URL}/pictures`);
    setPictures(response.data);
  };

  const fetchVideos = async () => {
    const response = await axios.get(`${BASE_URL}/videos`);
    setVideos(response.data);
    if (response.data.length > 0) {
      setSelectedVideo(response.data[0]);
    }
  };

  const fetchDialogues = async () => {
    const response = await axios.get(`${BASE_URL}/dialogues`);
    setDialogues(response.data);
  };

  const handleClickOpen = (picture) => {
    const img = new Image();
    img.src = `${BASE_URL}/${picture.path}`;
    img.onload = () => {
      setCurrentImage(picture);
      setOpen(true);
    };
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleExpandClick = (index) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [index]: !prevExpanded[index],
    }));
  };

  const handleBookClickOpen = () => {
    setOpenBookDialog(true);
  };

  const handleBookClose = () => {
    setOpenBookDialog(false);
  };

  const handleFileChange = (event) => {
    setPicturesToUpload(event.target.files);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('dateOfBooking', bookingDate.toISOString());
    formData.append('name', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('description', description);
    for (let i = 0; i < picturesToUpload.length; i++) {
      formData.append('pictures', picturesToUpload[i]);
    }
  
    try {
      await axios.post(`${BASE_URL}/signup`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      handleBookClose();
    } catch (error) {
      console.error('Error submitting the booking form', error);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', width: '100%', margin: '0 auto', overflow: 'hidden' }}>
      <CssBaseline />
      
      <NavbarV2/>
      <Box sx={{ flexGrow: 3,  display: 'flex', flexDirection: 'column' }}>
      <HeroBox
          sx={{
            marginTop: '64px',
            height: { xs: '200px', sm: '400px' },
            padding: { xs: 1, sm: 2 },
          }}
          style={{ backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0)), url(${pictureReel[currentPictureIndex]})` }}
        >
          <Typography
            variant="contained"
            sx={{
              fontSize: { xs: '1rem', sm: '1.5rem' },
              marginBottom: { xs: 1, sm: 2 },
            }}
          >
            Transform your home today
          </Typography>
            
 
    <Button variant="contained" sx={{ mt: 2, backgroundColor: '#ffbb33', color: '#fff', paddingX: 3, paddingY: 1.5, fontSize: '16px' }} onClick={handleBookClickOpen}>
      BOOK US TODAY
    </Button>


        </HeroBox>  


      
      <Grid container >
 
      <Grid item xs={12}>
  
      <Guarantee/>
  
        
  </Grid>
  <Grid item xs={12}>
  
  <Testimonials />
  
        
  </Grid>
  <Grid item xs={12}>
  
  
  <Servicesprovided />
  
  
        
  </Grid>
  <Grid item xs={12}>
  
  
  <Box sx={{ backgroundColor: '#2196f3', color: '#fff', p: 2, textAlign: 'center' }}>
          <Typography variant="body1">Mete Build V1</Typography>
        </Box>
  
  
  
        
  </Grid>
      </Grid>
          
  
       
     
          
        
             
                
  
  
      
  
           
         
          
  
     
      </Box>
     


















      
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="lg"
        fullScreen={fullScreen}
        PaperProps={{
          style: {
            maxWidth: '90vw',
            maxHeight: '90vh',
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {currentImage.title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box
            component="img"
            src={`${BASE_URL}/${currentImage.path}`}
            alt={currentImage.title}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <Dialog open={openBookDialog} onClose={handleBookClose} fullWidth maxWidth="sm">
        <DialogTitle>
          BOOK US TODAY
          <IconButton
            aria-label="close"
            onClick={handleBookClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>

          
          <Box component="form" sx={{ mt: 2 }} onSubmit={handleSubmit}>
          <Box
  display="flex"
  flexDirection="column"
  alignItems="center"
  justifyContent="center"
  sx={{ width: '100%', mt: 2 }}
>
  <Typography variant="body2" color="textSecondary">
    Select a date for booking:
  </Typography>
  <Calendar
    onChange={setBookingDate}
    value={bookingDate}
    tileClassName={({ date, view }) => {
      if (view === 'month' && date.getDay() === 0) {
        return 'highlight-sunday';
      }
    }}
  />
</Box>
         
            <TextField
              required
              fullWidth
              label="Name"
              margin="normal"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              required
              fullWidth
              label="Last Name"
              margin="normal"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              required
              fullWidth
              label="Email"
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              fullWidth
              label="Description"
              margin="normal"
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              style={{ marginTop: '16px', marginBottom: '16px' }}
            />
            
            <Button type="submit" variant="contained" sx={{ mt: 2 }}>
              Confirm Booking
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
