import * as React from 'react';
import { useState, useEffect } from 'react'; // Added useEffect
import { styled } from '@mui/material/styles';
import {
  Box,
  Grid,
  Card,
  CardContent,
  Button,
  Typography,
  Divider,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import axios from 'axios';
import BASE_URL from './ipconfig'; // Ensure this path is correct

// Styling for Pricing Cards
const PricingCard = styled(Card)(({ theme, selected }) => ({
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  transform: selected ? 'scale(1.05)' : 'scale(1)',
  boxShadow: selected ? theme.shadows[8] : theme.shadows[2],
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[8],
  },
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: 'column',
  height: '100%', // Ensure all cards stretch to the full height
  maxWidth: '300px', // Set your desired max width
  margin: 'auto', // Center the card within its Grid item
}));

// Section box styling for background
const SectionBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#e0f7fa',
  padding: theme.spacing(2), // Reduced padding
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2), // Reduced bottom margin
  textAlign: 'center', // Removed maxWidth and margin to allow full-width background
}));

export default function Tiers() {
  const [selectedCard, setSelectedCard] = useState(null);

  // New state variables for booking dialog
  const [openBookDialog, setOpenBookDialog] = useState(false);
  const [bookingDate, setBookingDate] = useState(new Date());
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [picturesToUpload, setPicturesToUpload] = useState([]);
  const [selectedTierName, setSelectedTierName] = useState(''); // To store selected tier/package name

  // Tiers data
  const tiers = [
    {
      name: 'Basic',
      price: 'Starts from $1500',
      features: [
        'Static website',
        'Simple visual design',
        'Responsive layout',
        'Basic SEO optimization',
        'Contact information display',
      ],
      color: '#2196f3', // Blue
    },
    {
      name: 'Standard',
      price: 'Starts from $3000',
      features: [
        'Includes all features from the Basic tier',
        'Dynamic website with backend',
        'Basic content management',
        'Database integration',
        'Standard SEO optimization',
        'Modular system integration',
        'Custom system setup',
      ],
      color: '#4caf50', // Green
    },
    {
      name: 'Premium',
      price: 'Starts from $5000',
      features: [
        'Includes all features from the Standard tier',
        'Full-featured dynamic website',
        'Advanced backend functionality',
        'Video modification capabilities',
        'SMTP server setup',
        'Booking system setup',
        'Domain setup and configuration',
      ],
      color: '#ff9800', // Orange
    },
  ];

  const handleCardClick = (index) => {
    setSelectedCard(index);
  };

  // Handle opening of the booking dialog
  const handleBookClickOpen = (tierName) => {
    setSelectedTierName(tierName); // Set the selected tier name
    setOpenBookDialog(true);
  };

  // Handle closing of the booking dialog
  const handleBookClose = () => {
    setOpenBookDialog(false);
    // Reset form fields if necessary
    setFirstName('');
    setLastName('');
    setEmail('');
    setDescription('');
    setPicturesToUpload([]);
    setBookingDate(new Date());
  };

  const handleFileChange = (event) => {
    setPicturesToUpload(event.target.files);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('dateOfBooking', bookingDate.toISOString());
    formData.append('name', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('description', description);
    formData.append('selectedTier', selectedTierName); // Include selected tier
    for (let i = 0; i < picturesToUpload.length; i++) {
      formData.append('pictures', picturesToUpload[i]);
    }

    try {
      await axios.post(`${BASE_URL}/signup`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      handleBookClose();
    } catch (error) {
      console.error('Error submitting the booking form', error);
    }
  };

  return (
    <SectionBox>
      <Container maxWidth="md">
        <Grid container spacing={2} justifyContent="center" alignItems="stretch">
          <Grid item xs={12}>
            <Typography component="h2" variant="h4" color="text.primary">
              Explore Our Packages
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Tailored Web Development Solutions to Elevate Your Business. From custom websites to full-stack development, we offer cutting-edge solutions that meet your specific needs. Let’s build something great together!
            </Typography>
          </Grid>

          {tiers.map((tier, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <PricingCard
                selected={selectedCard === index}
                onClick={() => handleCardClick(index)}
                sx={{
                  backgroundColor: tier.color,
                  color: '#fff',
                  borderRadius: '16px',
                  height: '100%',
                }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h1" gutterBottom sx={{ fontWeight: 'bold', fontSize: '2rem' }}>
                    {tier.name}
                  </Typography>
                  <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.4)' }} />
                  <Typography variant="h2" color="inherit" sx={{ fontWeight: 'bold', margin: '16px 0', fontSize: '1.5rem' }}>
                    {tier.price}
                  </Typography>
                  <ul style={{ paddingLeft: '16px' }}>
                    {tier.features.map((feature, i) => (
                      <li key={i}>
                        <Typography variant="body2" color="inherit" sx={{ fontSize: '0.9rem', textAlign: 'left' }}>
                          {feature}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </CardContent>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    mt: 2,
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    borderRadius: '12px',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.4)',
                    },
                  }}
                  onClick={() => handleBookClickOpen(tier.name)} // Open booking dialog with selected tier
                >
                  Book {tier.name} Package
                </Button>
              </PricingCard>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Booking dialog */}
      <Dialog open={openBookDialog} onClose={handleBookClose} fullWidth maxWidth="sm">
        <DialogTitle>
          BOOK {selectedTierName.toUpperCase()} PACKAGE 
          <IconButton
            aria-label="close"
            onClick={handleBookClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box component="form" sx={{ mt: 2 }} onSubmit={handleSubmit}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              sx={{ width: '100%', mt: 2 }}
            >
              <Typography variant="body2" color="textSecondary">
                Select a date for booking:
              </Typography>
              <Calendar
                onChange={setBookingDate}
                value={bookingDate}
                tileClassName={({ date, view }) =>
                  view === 'month' && date.getDay() === 0 ? 'highlight-sunday' : null
                }
              />
            </Box>
            <TextField
              required
              fullWidth
              label="First Name"
              margin="normal"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              required
              fullWidth
              label="Last Name"
              margin="normal"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              required
              fullWidth
              label="Email"
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              fullWidth
              label="Description"
              margin="normal"
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              style={{ marginTop: '16px', marginBottom: '16px' }}
            />
            <Button type="submit" variant="contained" sx={{ mt: 2 }}>
              Confirm Booking
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </SectionBox>
  );
}
