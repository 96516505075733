import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Divider, Container } from '@mui/material';

// Styling for Pricing Cards
const PricingCard = styled(Card)(({ theme, selected }) => ({
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  transform: selected ? 'scale(1.05)' : 'scale(1)',
  boxShadow: selected ? theme.shadows[8] : theme.shadows[2],
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[8],
  },
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',  // Ensure all cards stretch to the full height
  maxWidth: '300px', // Set your desired max width
  margin: 'auto',    // Center the card within its Grid item
}));

// Section box styling for background
const SectionBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#e0f7fa',
  padding: theme.spacing(2),  // Reduced padding
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),  // Reduced bottom margin
  textAlign: 'center',
  // Removed maxWidth and margin to allow full-width background
}));

export default function Tiers() {
  const [selectedCard, setSelectedCard] = useState(null);

  // Tiers data
const tiers = [
    {
      name: 'Basic',
      price: 'Starts from $1500',
      features: [
        'Static website',
        'Simple visual design',
        'Responsive layout',
        'Basic SEO optimization',
        'Contact information display',
      ],
      color: '#2196f3',  // Blue
    },
    {
      name: 'Standard',
      price: 'Starts from $3000',
      features: [
        'Includes all features from the Basic tier',
        'Dynamic website with backend',
        'Basic content management',
        'Database integration',
        'Standard SEO optimization',
        'Modular system integration',
        'Custom system setup',
        
      ],
      color: '#4caf50',  // Green
    },
    {
      name: 'Premium',
      price: 'Starts from $5000',
      features: [
        'Includes all features from the Standard tier',
        'Full-featured dynamic website',
        'Advanced backend functionality',
        'Video modification capabilities',
        'SMTP server setup',
        'Booking system setup',
        'Domain setup and configuration',
      
      ],
      color: '#ff9800',  // Orange
    },
  ];
  const handleCardClick = (index) => {
    setSelectedCard(index);
  };

  return (
    <SectionBox>
      <Container maxWidth="md"> {/* Moved Container inside SectionBox */}
      <Grid container spacing={2} justifyContent="center" alignItems="stretch">
      <Grid item  xs={12} >
      <Typography component="h2" variant="h4" color="text.primary">
      Explore Our Packages
        </Typography>
        <Typography variant="body1" color="text.secondary">
        Tailored Web Development Solutions to Elevate Your Business. From custom websites to full-stack development, we offer cutting-edge solutions that meet your specific needs. Let’s build something great together!






        </Typography>
        </Grid>
     
       
          {tiers.map((tier, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <PricingCard
                selected={selectedCard === index}
                onClick={() => handleCardClick(index)}
                sx={{
                  backgroundColor: tier.color,
                  color: '#fff',
                  borderRadius: '16px',
                  height: '100%',
                }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h1" gutterBottom sx={{ fontWeight: 'bold', fontSize: '2rem' }}>
                    {tier.name}
                  </Typography>
                  <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.4)' }} />
                  <Typography variant="h2" color="inherit" sx={{ fontWeight: 'bold', margin: '16px 0',fontSize: '1.5rem' }}>
                    {tier.price}
                  </Typography>
                  <ul style={{ paddingLeft: '16px' }}>
                    {tier.features.map((feature, i) => (
                      <li key={i}>
                        <Typography variant="body2" color="inherit" sx={{ fontSize: '0.9rem',textAlign: 'left'  }}>
                          {feature}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </CardContent>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    mt: 2,
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    borderRadius: '12px',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.4)',
                    },
                  }}
                >
                  {selectedCard === index ? 'Selected' : 'Select'}
                  
                </Button>
              </PricingCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </SectionBox>
  );
}
