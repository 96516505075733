import * as React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import BASE_URL from './ipconfig'; // Import the BASE_URL

export default function ServiceWork() {
  const theme = useTheme();
  const [services, setServices] = useState([]);

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/dialogues`);
      const formattedServices = response.data.map(dialogue => ({
        id: dialogue.id,
        title: dialogue.title,
        image: `${BASE_URL}/${dialogue.picture}`,
        description: dialogue.text
      }));
      setServices(formattedServices);
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: 4 }}>
      <Grid container spacing={4}>
        {services.map((service, index) => (
          <Grid item xs={12} key={service.id}>
            <Card sx={{ display: 'flex', flexDirection: { xs: 'column', md: index % 2 === 0 ? 'row' : 'row-reverse' }, alignItems: 'center' }}>
              <CardMedia
                component="img"
                sx={{ width: { xs: '100%', md: 300 }, height: { xs: 200, md: '100%' }, borderRadius: 2 }}
                image={service.image}
                alt={service.title}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, p: 2 }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography component="div" variant="h5">
                    {service.title}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    color="text.secondary" 
                    component="div" 
                    sx={{ 
                      whiteSpace: 'pre-wrap', 
                      wordWrap: 'break-word', 
                      overflowWrap: 'break-word',
                      maxWidth: '100%' // Ensure text does not overflow
                    }}
                  >
                    {service.description}
                  </Typography>
                </CardContent>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
