import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import Awards from './Awards';
import StarScore from './Starscore';











const googleReviews = [

  {
    avatar: <Avatar alt="Christine Waselynchuk" src="https://tornado-plumbing.com/wp-content/uploads/2023/12/ChIJ9wNFnLM3K4gRi13EkdcmT4c_56c15700c28ce4544e437b12dcd6b13e.jpg" />,
    name: 'SNELITE PAINTING',
    occupation: '9 months ago',
    testimonial: "They did everything within the budget and time frame. They have exceeded our expectations.",
    rating: 5,
  },
  {
    avatar: <Avatar alt="Sergey Haletski" src="https://tornado-plumbing.com/wp-content/uploads/2023/12/ChIJ9wNFnLM3K4gRi13EkdcmT4c_a6a5a014fac53b763d58f1ee93538d90.jpg" />,
    name: 'Sandeep Dhanoa',
    occupation: '2 years ago',
    testimonial: 'Furkan is a true professional. He and his team were always on time, a big plus compared to other contractors. He took care of every little concern we had and work we added on. Furkan and his team made our backyard dream true.',
    rating: 5,
  },
  {
    avatar: <Avatar alt="Andrea Kelly" src="https://tornado-plumbing.com/wp-content/uploads/2023/12/ChIJ9wNFnLM3K4gRi13EkdcmT4c_fc3555802caf81c899f911430dff3221.jpg" />,
    name: 'Artistic Touch Maintenance',
    occupation: '1 months ago',
    testimonial: "Very Professional Craftsmanship of him and his team.",
    rating: 5,
  },
  {
    avatar: <Avatar alt="lorraine wood" src="https://tornado-plumbing.com/wp-content/uploads/2023/12/ChIJ9wNFnLM3K4gRi13EkdcmT4c_db0d36a09f104c2ae73062a37e44aad8.jpg" />,
    name: 'Michelle Au',
    occupation: '2 months ago',
    testimonial: "Got my deck done by Gunner and his crew did an amazing job with my deck highly recommend",
    rating: 5,
  },
  {
    avatar: <Avatar alt="Oleg Belenki" src="https://tornado-plumbing.com/wp-content/uploads/2023/12/ChIJ9wNFnLM3K4gRi13EkdcmT4c_add08602b37b427af3ce9626266f9255.jpg" />,
    name: 'Halil Dağköy',
    occupation: '5 years ago',
    testimonial: "I am very pleased with the service that I received from Mr.Gunner. He was very thorough with pricing.",
    rating: 5,
  },
  {
    avatar: <Avatar alt="Claudia Taylor" src="https://tornado-plumbing.com/wp-content/uploads/2023/12/ChIJ9wNFnLM3K4gRi13EkdcmT4c_070f539ecbb028221790fe07056a34c0.jpg" />,
    name: 'Vasisht Chari',
    occupation: '3 months ago',
    testimonial: "Excellent fence building with a focus on customer satisfaction! Would highly recommend this builder for future fencing projects!",
    rating: 5,
  },
];

const renderStars = (rating) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {Array.from({ length: 5 }).map((_, index) =>
        index < rating ? (
          <StarIcon key={index} sx={{ color: '#fb8e28' }} />
        ) : (
          <StarBorderIcon key={index} sx={{ color: '#fb8e28' }} />
        )
      )}
    </Box>
  );
};

export default function ProgressMobileStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md')); // Checks if the screen is larger than the 'md' breakpoint
  const reviewsPerPage = isLargeScreen ? 3 : 1; // Show 3 reviews per page on large screens, 1 review per page on small screens
  const maxSteps = Math.ceil(googleReviews.length / reviewsPerPage);

  // Adjust the activeStep when the screen size changes
  React.useEffect(() => {
    if (activeStep >= maxSteps) {
      setActiveStep(maxSteps - 1);
    }
  }, [reviewsPerPage, maxSteps, activeStep]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Container
    id="testimonials"
    sx={{
      pt: { xs: 4, sm: 12 },
      pb: { xs: 8, sm: 16 },
      position: 'relative',
      display: 'flex',
      backgroundColor: '#ffb74d' ,
      flexDirection: 'column',
      alignItems: 'center',
      gap: { xs: 3, sm: 6 },
    }}
  >
<Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Awards & Testimonials
        </Typography>
        <Typography variant="body1" color="text.secondary">
        Discover Why Our Customers Love Our Landscaping Services. Experience our unmatched efficiency, exceptional quality, and commitment to customer satisfaction. Book us today to transform your outdoor spaces and bring your landscaping dreams to life!







        </Typography>
      </Box>


<Grid container spacing={2}  >
    <Box sx={{ maxWidth: '100%', flexGrow: 1 }}>

      
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 2, p: 2 }}>
        {googleReviews.slice(activeStep * reviewsPerPage, (activeStep + 1) * reviewsPerPage).map((review, index) => (
          <Card key={index} sx={{ width: '100%', maxWidth: '32%', minWidth: isLargeScreen ? '30%' : '100%' }}>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                {review.avatar}
                <Box sx={{ ml: 2, flexGrow: 1 }}>
                  <Typography variant="subtitle1">{review.name}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {review.occupation}
                  </Typography>
                  {renderStars(review.rating)}
                </Box>
              </Box>
              <Box sx={{ maxHeight: 100, overflowY: 'auto' }}>
                <Typography variant="body2" color="text.secondary">
                  {review.testimonial}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
      <MobileStepper
        variant="progress"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        sx={{ maxWidth: 400, flexGrow: 1, margin: 'auto' }}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
    </Grid>

    </Container>
  );
}
