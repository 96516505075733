import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import plumbing1 from './works.png';
import plumbing2 from './nicelook.png';
import plumbing3 from './codemaybe.png';
import plumbing4 from './website1.png';
import signature from './signature.png'; // Importing the signature image

// Styled SectionBox to expand background color full-width
const SectionBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#f5f5f5', // Use your desired background color
  padding: theme.spacing(4, 0), // Vertical padding
}));

const Guarantee = () => {
  return (
    <SectionBox>
      <Container>
        <Grid container spacing={2} alignItems="center">
          {/* Left side images */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box
                  component="img"
                  src={plumbing1}
                  alt="Project 1"
                  sx={{
                    width: '100%',
                    height: '200px',
                    objectFit: 'cover',
                    borderRadius: '8px',
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Box
                  component="img"
                  src={plumbing2}
                  alt="Project 2"
                  sx={{
                    width: '100%',
                    height: '200px',
                    objectFit: 'cover',
                    borderRadius: '8px',
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Box
                  component="img"
                  src={plumbing3}
                  alt="Project 3"
                  sx={{
                    width: '100%',
                    height: '200px',
                    objectFit: 'cover',
                    borderRadius: '8px',
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Box
                  component="img"
                  src={plumbing4}
                  alt="Project 4"
                  sx={{
                    width: '100%',
                    height: '200px',
                    objectFit: 'cover',
                    borderRadius: '8px',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Right side text content */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" sx={{ color: '#555', mb: 1 }}>
              OUR GUARANTEE
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
              Trust Illuma Solutions for Your Next Web Project!
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="body1"
                sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
              >
                <Box
                  component="span"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    color: '#FFCC00',
                    mr: 1,
                  }}
                >
                  💻
                </Box>
                Cutting-edge, responsive web design guaranteed!
              </Typography>
              <Typography
                variant="body1"
                sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
              >
                <Box
                  component="span"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    color: '#FFCC00',
                    mr: 1,
                  }}
                >
                  ⚙️
                </Box>
                Seamless project management—delivered on time!
              </Typography>
              <Typography
                variant="body1"
                sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
              >
                <Box
                  component="span"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    color: '#FFCC00',
                    mr: 1,
                  }}
                >
                  🔒
                </Box>
                Secure, scalable solutions tailored to your needs!
              </Typography>
            </Box>
            <Typography
              variant="body2"
              sx={{ fontStyle: 'italic', fontWeight: 'bold', mt: 2 }}
            >
              Mete Isiksalan
            </Typography>
            <Typography variant="body2">
              Founder & CEO, Illuma Solutions
            </Typography>
           
          </Grid>
        </Grid>
      </Container>
    </SectionBox>
  );
};

export default Guarantee;
