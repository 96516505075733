import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import axios from 'axios';
import BASE_URL from './ipconfig';
import { styled } from '@mui/material/styles';

// Styled SectionBox to expand background color full-width
const SectionBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#0d47a1', // Changed to blue
  color: '#fff', // Default text color is white
  padding: theme.spacing(4, 0), // Vertical padding
}));

export default function Hiring() {
  const [openBookDialog, setOpenBookDialog] = useState(false);
  const [bookingDate, setBookingDate] = useState(new Date());
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [picturesToUpload, setPicturesToUpload] = useState([]);
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleBookClickOpen = () => {
    setOpenBookDialog(true);
  };

  const handleBookClose = () => {
    setOpenBookDialog(false);
  };

  const handleFileChange = (event) => {
    setPicturesToUpload(event.target.files);
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('dateOfBooking', bookingDate.toISOString());
    formData.append('name', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('description', description);
    for (let i = 0; i < picturesToUpload.length; i++) {
      formData.append('pictures', picturesToUpload[i]);
    }

    try {
      await axios.post(`${BASE_URL}/signup`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      handleBookClose();
    } catch (error) {
      console.error('Error submitting the booking form', error);
    }
  };

  const fetchVideos = async () => {
    const response = await axios.get(`${BASE_URL}/videos`);
    setVideos(response.data);
    if (response.data.length > 0) {
      setSelectedVideo(response.data[0]);
    }
  };

  return (
    <SectionBox>
      <Container>
        <Grid container spacing={2} alignItems="center">
          {/* Left text content */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" sx={{ color: '#b0bec5', fontWeight: 'bold', mb: 2 }}>
              Empower Your Online Presence with Expertise.
            </Typography>
            <Typography
              variant="h2"
              sx={{  fontWeight: 'bold', mb: 2 }} // Light grey color
            >
              Let Us Transform Your Digital Landscape
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, color: '#b0bec5' }}>
              "Illuma Solutions revamped our outdated website into a modern, responsive platform that has
              significantly increased our online traffic. Their team was professional, innovative, and delivered beyond
              our expectations. We're absolutely thrilled with the results!"
              <br />
              <strong>– Serhiy (Company Owner)</strong>
            </Typography>
          </Grid>

          {/* Right video content */}
          <Grid item xs={12} sm={6} sx={{ textAlign: 'center', mt: 2 }}>
            <Box
              sx={{
                backgroundColor: '#1a237e', // Darker shade of blue for the box
                p: 3,
                borderRadius: '8px',
                display: 'inline-block',
                boxShadow: 3,
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Your digital transformation starts here.
              </Typography>
              <Button
                variant="contained"
                sx={{
                  mt: 2,
                  backgroundColor: '#1976d2', // Lighter shade of blue for the button
                  color: '#fff',
                  paddingX: 3,
                  paddingY: 1.5,
                  fontSize: '16px',
                }}
                onClick={handleBookClickOpen}
              >
                BOOK US TODAY
              </Button>

              <Divider
      sx={{
        my: 2,
        width: '80%',
        margin: '16px auto',
        '&::before, &::after': {
          borderColor: '#ffffff',
          borderBottomWidth: '2px',
        },
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#ffffff' }}>
        OR
      </Typography>
    </Divider>

              <Button
                variant="contained"
                sx={{
                  mt: 1,
                  backgroundColor: '#1976d2', // Same lighter blue for consistency
                  color: '#fff',
                  paddingX: 3,
                  paddingY: 1.5,
                  fontSize: '16px',
                }}
                href="tel:(226) 235-9996​"
              >
                CALL US: (226) 235-9996
              </Button>
            </Box>
          </Grid>

          {/* Bottom button */}
          
        </Grid>

        {/* Booking dialog */}
        <Dialog open={openBookDialog} onClose={handleBookClose} fullWidth maxWidth="sm">
          <DialogTitle>
            BOOK US TODAY
            <IconButton
              aria-label="close"
              onClick={handleBookClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Box component="form" sx={{ mt: 2 }} onSubmit={handleSubmit}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ width: '100%', mt: 2 }}
              >
                <Typography variant="body2" color="textSecondary">
                  Select a date for booking:
                </Typography>
                <Calendar
                  onChange={setBookingDate}
                  value={bookingDate}
                  tileClassName={({ date, view }) =>
                    view === 'month' && date.getDay() === 0 ? 'highlight-sunday' : null
                  }
                />
              </Box>
              <TextField
                required
                fullWidth
                label="First Name"
                margin="normal"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <TextField
                required
                fullWidth
                label="Last Name"
                margin="normal"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              <TextField
                required
                fullWidth
                label="Email"
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                fullWidth
                label="Description"
                margin="normal"
                multiline
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <input
                type="file"
                multiple
                onChange={handleFileChange}
                style={{ marginTop: '16px', marginBottom: '16px' }}
              />
              <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                Confirm Booking
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </Container>
    </SectionBox>
  );
}
